import React from 'react';
import { Link } from 'gatsby';
import Seo from "../components/seo";
import Layout from '../components/layout';

const toolGroups = [
  {
    name: "Image Conversion",
    tools: [
      { 
        name: 'HEIC to JPEG Converter', 
        description: 'Convert HEIC images to JPEG format', 
        path: '/tools/heic-to-jpeg-converter' 
      },
      { 
        name: 'SVG to JPEG/PNG Converter', 
        description: 'Convert SVG images to JPEG or PNG format', 
        path: '/tools/svg-to-jpeg-png-converter' 
      },
    ]
  },
  {
    name: "Text Tools",
    tools: [
      {
        name: 'Word Counter',
        description: 'Count words in your text',
        path: 'https://www.nombredemots.com/'
      },
    ]
  }
];

const ToolsPage = () => {
  return (
    <Layout>
      <Seo title="Browser based tools" />
      <h2 className="text-4xl font-bold mb-6 border-b pb-4 text-blue-800">Browser based tools</h2>
      <div className="space-y-8">
        {toolGroups.map((group, groupIndex) => (
          <div key={groupIndex} className="bg-white shadow-md rounded-lg overflow-hidden border-2 border-blue-200 p-6">
            <h3 className="text-2xl font-bold mb-4 text-blue-700">{group.name}</h3>
            <ul className="space-y-2">
              {group.tools.map((tool, toolIndex) => (
                <li key={toolIndex}>
                  {tool.path.startsWith('http') ? (
                    <a 
                      href={tool.path} 
                      className="text-blue-600 hover:text-blue-800 transition-colors duration-300"
                      target="_blank" 
                      rel="noopener noreferrer"
                    >
                      {tool.name}
                    </a>
                  ) : (
                    <Link 
                      to={tool.path} 
                      className="text-blue-600 hover:text-blue-800 transition-colors duration-300"
                    >
                      {tool.name}
                    </Link>
                  )}
                  <p className="text-gray-600 text-sm">{tool.description}</p>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </Layout>
  );
};

export default ToolsPage;